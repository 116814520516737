"use client";

import { getDictionary } from "@/lib/dictionary";
import { message as messageApi } from "antd";
import { useRouter, useSearchParams } from "next/navigation";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import LoadingOverlay from "react-loading-overlay-ts";
import { Locale } from "../../../../i18n.config";
interface FormData {
  phone: string;
}
export default function SignIn({
  params: {
    lang
  }
}: Readonly<{
  params: {
    lang: Locale;
  };
}>) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: {
      errors,
      isValid
    }
  } = useForm({
    mode: "onChange",
    // Validate the form as the user types
    criteriaMode: "all",
    defaultValues: {
      phone: ""
    }
  });
  const phone = watch("phone");
  const router = useRouter();
  const [pages, setPages] = React.useState<{
    signin: {
      loginPrompt: string;
      brandName: string;
      accountPrompt: string;
      inputPlaceHolder: string;
      rememberMe: string;
      continueButton: string;
      inputTotalError: string;
      inputStartError: string;
      inputCountError: string;
      termsAndPolicyPrefix: string;
      termsOfUse: string;
      and: string;
      privacyPolicy: string;
    };
  } | null>(null);
  const [isActive, setIsActive] = useState(false);
  const searchParams = useSearchParams();
  const phoneFromUrl = searchParams.get("phone");
  useEffect(() => {
    async function fetchData() {
      const data = await getDictionary(lang);
      setPages(data.pages);
    }
    fetchData();
  }, [lang]);
  useEffect(() => {
    if (phoneFromUrl && typeof phoneFromUrl === "string" && phoneFromUrl !== phone) {
      setValue("phone", phoneFromUrl, {
        shouldValidate: true
      });
    }
  }, []);

  // Only update the search params if the phone number has changed and is valid
  useEffect(() => {
    if (isValid && phone && phone !== phoneFromUrl) {
      router.replace(`/${lang}/signin?phone=${encodeURIComponent(phone)}`, {
        scroll: false
      });
    }
  }, [phone, isValid, phoneFromUrl, lang, router]);
  const onSubmit = async (data: FormData) => {
    if (!isValid) return;

    // Activate the loading overlay
    setIsActive(true);

    // URL of the backend endpoint
    const apiUrl = `/api/auth/send-otp`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          phone_number: `${data.phone}`
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.body}`);
      }
      router.push(`/${lang}/otp?phone=${encodeURIComponent(data.phone)}`);
    } catch (error) {
      messageApi.error({
        content: `Failed to send OTP:${error}`,
        duration: 10,
        className: "custom-class"
      });
    } finally {
      // Deactivate the loading overlay
      setIsActive(false);
    }
  };
  return <LoadingOverlay active={isActive} spinner data-sentry-element="LoadingOverlay" data-sentry-component="SignIn" data-sentry-source-file="page.tsx">
      <div className={`bg-white min-h-screen px-[1.25rem] py-[1.88rem] overflow-y-auto ${lang === "ar" ? "text-right" : "text-left"} flex flex-col`}>
        <header className="pt-[0.75rem] pb-[1.5rem] flex justify-center">
          <img src="/assets/icons/Livii-Logo.svg" alt="Livii Logo" className="w-[1.625rem] h-[1.625rem]" />
        </header>

        <h1 className="text-[1.125rem] font-semibold text-center mb-[2.5rem] leading-[22px]">
          {pages?.signin.loginPrompt}
          <span className="text-mainBG"> {pages?.signin.brandName}</span>{" "}
          {pages?.signin.accountPrompt}
        </h1>

        <img src="/assets/images/group.svg" alt="Delivery Illustration" className="w-full h-[20.375rem] mb-[3.14rem]" />

        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="phone" className="block text-sm font-[400] text-gray-700 mb-[0.62rem] leading-[16px]">
              {pages?.signin.inputPlaceHolder}
            </label>
            <input id="phone" type="tel" maxLength={8} className={`border p-4 w-full rounded-xl  ${lang === "ar" ? "text-right" : "left"}`} style={{
            height: "3.375rem"
          }} {...register("phone", {
            required: pages?.signin.inputTotalError,
            validate: {
              startsCorrectly: value => /^[234]/.test(value) || pages?.signin.inputStartError,
              isEightDigits: value => value.length === 8 || pages?.signin.inputCountError
            }
          })} />
            {errors.phone && <p className={`text-red-500 text-sm mt-2  ${lang === "ar" ? "text-right" : "text-left"}`}>
                {errors.phone.message}
              </p>}
          </div>
          <p className="text-[0.875rem] text-[#989898] text-start mt-[2.5rem] mb-[1.5rem] leading-[20px]">
            By using this app, you agree to our{" "}
            <span className="text-mainBG">Terms of Use</span> and{" "}
            <span className="text-mainBG">Private Policy</span>.
          </p>
          <button type="submit" className={`mb-[4.62rem] bg-mainBG text-white p-4 w-full rounded-xl hover:bg-green-700 transition-colors ${!isValid ? "opacity-50 cursor-not-allowed" : ""}`} disabled={!isValid}>
            {pages?.signin.continueButton}
          </button>
        </form>
      </div>
    </LoadingOverlay>;
}